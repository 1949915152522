var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationListener',{on:{"getIsValid":function (isValid) { return (_vm.DeviceLocation.isDataValid = isValid); }}},[_c('div',[_c('loading',{attrs:{"active":_vm.app.showLoader.value,"can-cancel":true}}),_c('div',{staticClass:"modal-header"},[_vm._v(_vm._s(_vm.locationCardHeader))]),_c('div',{staticClass:"modal-content scroll"},[_c('div',{staticClass:"form-vertical m-0"},[_c('div',{staticClass:"form-layout-horizondal"},[_c('label',{staticClass:"w-25"},[_vm._v("Location Name *")]),_c('ValidationHandler',{attrs:{"name":_vm.DeviceLocation.postData.location.name,"rules":"required|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('TextInput',{attrs:{"type":"text","label":"Location Name ","placeholder":"Location Name ","required":true,"error":error},model:{value:(_vm.DeviceLocation.postData.location.name),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.location, "name", $$v)},expression:"DeviceLocation.postData.location.name"}})]}}])})],1),_c('div',{staticClass:"form-layout-horizondal"},[_c('label',{staticClass:"w-25"},[_vm._v("Region Name")]),_c('ValidationHandler',{attrs:{"name":_vm.DeviceLocation.postData.location.regionID}},[_c('select-input',{attrs:{"name":"Region Name","displayName":"Region Name","placeHolder":"Region Name","options":_vm.regionList.map(function (x) { return ({
                  value: x.id,
                  text: x.name,
                }); })},model:{value:(_vm.DeviceLocation.postData.location.regionID),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.location, "regionID", $$v)},expression:"DeviceLocation.postData.location.regionID"}})],1)],1),_c('div',{staticClass:"form-layout-horizondal",on:{"click":function($event){return _vm.show()}}},[_c('label',{staticClass:"w-25"},[_vm._v("Device *")]),(_vm.isEdit)?_c('span',[_c('text-input',{attrs:{"readonly":true},model:{value:(_vm.DeviceLocation.postData.DeviceLocation.device.name),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.DeviceLocation.device, "name", $$v)},expression:"DeviceLocation.postData.DeviceLocation.device.name"}})],1):_c('ValidationHandler',{attrs:{"name":_vm.DeviceLocation.postData.DeviceLocation.deviceId,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var error = ref.error;
return [_c('select-input',{attrs:{"name":"Device","displayName":"Device","placeHolder":"Device ","required":true,"options":_vm.deviceList.map(function (x) { return ({
                  value: x.id,
                  text: x.name,
                }); }),"error":error},model:{value:(_vm.DeviceLocation.postData.DeviceLocation.deviceId),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.DeviceLocation, "deviceId", $$v)},expression:"DeviceLocation.postData.DeviceLocation.deviceId"}})]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deviceShow),expression:"deviceShow"}],staticClass:"form-layout-horizondal"},[_c('label',{staticClass:"w-25"},[_vm._v("Device Status")]),_c('ValidationHandler',{attrs:{"name":_vm.DeviceLocation.postData.DeviceLocation.deviceStatus}},[_c('select-input',{attrs:{"name":"Device Status","displayName":"Device Status","placeHolder":"Device Status","options":_vm.deviceStatus.map(function (x) { return ({
                  value: x.lookupId,
                  text: x.displayValue,
                }); })},model:{value:(_vm.DeviceLocation.postData.DeviceLocation.deviceStatus),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.DeviceLocation, "deviceStatus", $$v)},expression:"DeviceLocation.postData.DeviceLocation.deviceStatus"}})],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deviceShow),expression:"deviceShow"}],staticClass:"form-layout-horizondal"},[_c('label',{staticClass:"w-25"},[_vm._v("Start Date")]),_c('text-input',{attrs:{"type":"date","label":"Start Date","required":true,"readonly":_vm.isEdit},model:{value:(_vm.DeviceLocation.postData.DeviceLocation.activationDate),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.DeviceLocation, "activationDate", $$v)},expression:"DeviceLocation.postData.DeviceLocation.activationDate"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.deviceShow),expression:"deviceShow"}],staticClass:"form-layout-horizondal"},[_c('label',{staticClass:"w-25"},[_vm._v("End Date")]),_c('text-input',{attrs:{"type":"date","label":"End Date","required":true},model:{value:(_vm.DeviceLocation.postData.DeviceLocation.lastActiveDate),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.DeviceLocation, "lastActiveDate", $$v)},expression:"DeviceLocation.postData.DeviceLocation.lastActiveDate"}})],1)]),_c('div',[(this.error == true)?_c('div',[_c('h6',{staticStyle:{"color":"red","padding-left":"70px"}},[_vm._v(" ** End date cannot be less than start date ** ")]),_c('p')]):_vm._e()]),_c('div',{staticClass:"form-layout-horizondal"},[_c('label',{staticClass:"w-25"},[_vm._v("Address*")]),_c('ValidationHandler',{attrs:{"name":_vm.DeviceLocation.postData.location.address.address1,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var error = ref.error;
return [_c('TextInput',{attrs:{"type":"text","label":"Address ","placeholder":"Address ","required":true,"error":error},model:{value:(_vm.DeviceLocation.postData.location.address.address1),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.location.address, "address1", $$v)},expression:"DeviceLocation.postData.location.address.address1"}})]}}])})],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-layout-horizondal"},[_c('label',{staticClass:"w-25"},[_vm._v("City*")]),_c('ValidationHandler',{staticClass:"w-25",attrs:{"name":_vm.DeviceLocation.postData.location.address.city,"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var error = ref.error;
return [_c('TextInput',{attrs:{"type":"text","label":"City ","placeholder":"City ","required":true,"error":error},model:{value:(_vm.DeviceLocation.postData.location.address.city),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.location.address, "city", $$v)},expression:"DeviceLocation.postData.location.address.city"}})]}}])}),_c('label',{staticClass:"w-auto"},[_vm._v("State*")]),_c('ValidationHandler',{staticClass:"w-25",attrs:{"name":_vm.DeviceLocation.postData.location.address.state,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var error = ref.error;
return [_c('div',[_c('select-input',{attrs:{"name":"state","displayName":"State ","placeHolder":"state ","options":_vm.stateList.map(function (x) { return ({
                      value: x.id,
                      text: x.displayValue,
                    }); }),"required":true,"error":error},model:{value:(_vm.DeviceLocation.postData.location.address.state),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.location.address, "state", $$v)},expression:"DeviceLocation.postData.location.address.state"}})],1)]}}])})],1)]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-layout-horizondal"},[_c('label',{staticClass:"w-25"},[_vm._v("Country *")]),_c('span',{staticClass:"w-25"},[_c('ValidationHandler',{attrs:{"name":_vm.DeviceLocation.postData.location.address.country}},[_c('select-input',{attrs:{"name":"country","displayName":"Country *","placeHolder":"Country *","required":true,"options":_vm.countryCodeList.map(function (x) { return ({
                      value: x.id,
                      text: x.displayValue,
                    }); })},model:{value:(_vm.DeviceLocation.postData.location.address.country),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.location.address, "country", $$v)},expression:"DeviceLocation.postData.location.address.country"}})],1)],1),_c('label',{staticClass:"w-10"},[_vm._v("Zip")]),_c('span',{staticClass:"w-25"},[_c('ValidationHandler',{attrs:{"name":_vm.DeviceLocation.postData.location.address.zipCode,"rules":"required|min:5|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var error = ref.error;
return [_c('TextInput',{directives:[{name:"mask",rawName:"v-mask",value:('#####-#####-####'),expression:"'#####-#####-####'"}],attrs:{"type":"text","label":"Zip Code *","placeholder":"Zip Code *","required":true,"error":error},model:{value:(_vm.DeviceLocation.postData.location.address.zipCode),callback:function ($$v) {_vm.$set(_vm.DeviceLocation.postData.location.address, "zipCode", $$v)},expression:"DeviceLocation.postData.location.address.zipCode"}})]}}])})],1)])])])]),_c('div',{staticClass:"modal-footer"},[_c('md-button',{staticClass:"btn-outline",on:{"click":_vm.handleCancelClick}},[_vm._v("Cancel ")]),_c('div',[(this.error == true)?_c('div'):_c('div',[_c('md-button',{staticClass:"md-primary",on:{"click":_vm.handleLocationSave}},[_vm._v("Submit ")])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }