<template>
  <div class="md-content md-table-content">
    <md-table
      class="
        md-content md-table-content md-table-global-sales
        globe-activity
        Clientloc
        responsive
        hideheader
      "
    >
      <md-table-row>
        <md-table-head>Location Name</md-table-head>
        <md-table-head>Region Name</md-table-head>
        <md-table-head>Device</md-table-head>
        <md-table-head>Action</md-table-head>
      </md-table-row>
      <md-table-row
        v-for="item in locationDetails.postData"
        :key="item.id"
        slot="md-table-row"
      >
        <md-table-cell md-label="Location Name">{{
          item.location.name
        }}</md-table-cell>
        <md-table-cell md-label="Region Name">{{
          item.region.name
        }}</md-table-cell>
        <md-table-cell md-label="Device">{{ item.device.name }}</md-table-cell>
        <md-table-cell>
          <span v-if="isLoading">
            <spinner :diameter="23"></spinner>
          </span>
          <md-icon v-else @click.native="handleEdit(item)">edit</md-icon>
          <md-icon
            class="delete-icon"
            @click.native="handleDeleteDeviceLocation(item)"
            >delete</md-icon
          >
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
export default {
  name: "ClientTable",
  inject: [
    "locationDetails",
    "getClientDeviceLocation",
    "handleDeleteDeviceLocation",
    "progress",
  ],
  data() {
    return {
      showDialog: false,
      selectedId: "",
      isLoading: false,
    };
  },
  methods: {
    handleEdit(item) {
      this.getClientDeviceLocation(item);
    },
  },
};
</script>
<style scoped>
.Clientloc.responsive td:nth-of-type(1):before {
  content: "Location Name";
}
.Clientloc.responsive td:nth-of-type(2):before {
  content: "Region Name";
}
.Clientloc.responsive td:nth-of-type(3):before {
  content: "Device";
}
.Clientloc.responsive td:nth-of-type(4):before {
  content: "Action";
}
</style>
