<template>
  <div>
    <ValidationListener
      @getIsValid="(isValid) => (orgForm.isDataValid = isValid)"
    >
      <div>
        <div class="md-layout">
          <div class="md-layout-item">
            <h3 class="md-title m-0">Client</h3>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-card>
              <md-card-header>
                <h4 class="title">{{ manageClient.cardHeader }}</h4>
              </md-card-header>
              <div class="md-card-content">
                <loading
                  :active="app.showLoader.value"
                  :can-cancel="true"
                ></loading>
                <div class="md-layout">
                  <div class="md-layout-item md-size-40 md-small-size-100">
                    <h4 class="title">{{ manageClient.cardHeader }}</h4>
                    <div>
                      <div class="form-layout-horizondal">
                        <label>Client Name*</label>
                        <ValidationHandler
                          :name="orgForm.postData.clientName"
                          rules="required|alpha_dash"
                          v-slot="{ error }"
                        >
                          <TextInput
                            label="Client Name*"
                            placeholder="Client Name*"
                            :required="false"
                            v-model="orgForm.postData.clientName"
                            :error="error"
                            icons="perm_identity"
                          />
                        </ValidationHandler>
                      </div>
                      <div class="form-layout-horizondal">
                        <label>Business Type*</label>

                        <ValidationHandler
                          :name="orgForm.postData.businessType"
                          rules="required"
                          v-slot="{ error }"
                        >
                          <!-- <div class="text-field"> -->
                          <!-- <div class="form-layout-horizondal"> -->
                          <select-input
                            name="Business Type"
                            displayName="Business Type"
                            placeHolder="Business Type"
                            :options="
                              businessType.map((x) => ({
                                value: x.lookupId,
                                text: x.displayValue,
                              }))
                            "
                            v-model="orgForm.postData.businessType"
                            :required="true"
                            :error="error"
                          />
                          <!-- </div> -->
                        </ValidationHandler>
                      </div>
                      <div class="form-layout-horizondal">
                        <label>Business Name</label>
                        <ValidationHandler
                          :name="orgForm.postData.businessName"
                        >
                          <text-input
                            label="Business Name"
                            placeholder="Business Name"
                            v-model="orgForm.postData.businessName"
                            :required="true"
                          />
                        </ValidationHandler>
                      </div>
                      <div class="form-layout-horizondal">
                        <label>Domain*</label>
                        <ValidationHandler
                          :name="orgForm.postData.domain"
                          rules="required"
                          v-slot="{ error }"
                        >
                          <text-input
                            label="Domain"
                            placeholder="Domain"
                            v-model="orgForm.postData.domain"
                            :required="true"
                            :error="error"
                            sufix=".globechek.com"
                          />
                        </ValidationHandler>
                      </div>
                      <div class="form-layout-horizondal">
                        <label>Contact Name</label>
                        <ValidationHandler
                          :name="orgForm.postData.contactName"
                          rules="alpha_spaces"
                          v-slot="{ error }"
                        >
                          <TextInput
                            label="Contact Name"
                            placeholder="Contact Name"
                            :required="false"
                            v-model="orgForm.postData.contactName"
                            :error="error"
                          />
                        </ValidationHandler>
                      </div>
                      <div class="form-layout-horizondal">
                        <label>Contact Email</label>
                        <ValidationHandler
                          :name="orgForm.postData.contactEmail"
                          rules="email"
                          v-slot="{ error }"
                        >
                          <TextInput
                            type="text"
                            label="Email"
                            placeholder="Email"
                            :required="false"
                            v-model="orgForm.postData.contactEmail"
                            :error="error"
                            icons="email"
                          />
                        </ValidationHandler>
                      </div>
                      <div class="form-layout-horizondal">
                        <label>Contact Phone</label>
                        <ValidationHandler
                          :name="orgForm.postData.contactPhone"
                          rules="min:14|max:18"
                          v-slot="{ error }"
                        >
                          <TextInput
                            type="text"
                            label="Phone"
                            placeholder="Phone"
                            :required="true"
                            v-model="orgForm.postData.contactPhone"
                            v-mask="'(###) ###-########'"
                            :error="error"
                            icons="smartphone"
                          />
                        </ValidationHandler>
                      </div>

                      <div class="modal-footer">
                        <md-button class="btn-outline" @click="$router.back()"
                          >cancel
                        </md-button>
                        <md-button class="md-primary" @click="handleSave()"
                          >submit
                        </md-button>
                      </div>
                    </div>
                  </div>

                  <div class="md-layout-item md-size-60 md-small-size-100">
                    <h4 class="title">Location</h4>
                    <md-button @click="handleAddClick" class="md-danger-outline"
                      >Add Location
                    </md-button>
                    <div>
                      <h4 class="title">List of added Location</h4>
                      <md-card>
                        <Client-table />
                      </md-card>
                    </div>
                  </div>
                </div>
              </div>
            </md-card>
          </div>
        </div>
        <md-dialog
          :md-active.sync="manageClient.showManageClientLocation"
          :md-click-outside-to-close="false"
        >
          <div class="modal-size-medium">
            <client-location></client-location>
          </div>
        </md-dialog>
      </div>
    </ValidationListener>
  </div>
</template>
<script>
import TextInput from "../../components/controls/TextInput.vue";
import SelectInput from "../../components/controls/SelectInput.vue";
import ClientTable from "../../views/Client/ClientTable.vue";
import ClientLocation from "../../views/Client/ClientLocation.vue";
import { mapState } from "vuex";
import { Information } from "../../models/SwalMessages";
import { ClientLocationRequest } from "../../models/Client";

export default {
  components: {
    TextInput,
    SelectInput,
    ClientTable,
    ClientLocation,
  },
  inject: [
    "handleSave",
    "orgForm",
    "manageClient",
    "showError",
    "showInformation",
    "showWarning",
    "progress",
    "app",
    "getDeviceList",
    "DeviceLocation",
    "navigationBlocker",
    "popupInvoked",
  ],
  data() {
    return {
      details: {
        domain: 0,
        clientName: 0,
        businessType: 0,
        businessName: 0,
        contactName: 0,
        contactEmail: 0,
        contactPhone: 0,
      },
    };
  },
  watch: {
    "popupInvoked.val"() {
      this.manageClient.showManageClientLocation = false;
    },
    "orgForm.postData.domain": function () {
      this.details.domain = this.details.domain + 1;
      if (this.$route.path.includes("edit") && this.details.domain > 2)
        this.navigationBlocker.val = false;
      else if (this.$route.path.includes("add")) {
        this.navigationBlocker.val = false;
      }
      this.orgForm.postData.domain = this.orgForm.postData.domain
        .trim()
        .replace(".globechek.com", " ");
    },
    "orgForm.postData.clientName"() {
      this.details.clientName = this.details.clientName + 1;
      if (this.$route.path.includes("edit") && this.details.clientName > 1)
        this.navigationBlocker.val = false;
      else if (this.$route.path.includes("add")) {
        this.navigationBlocker.val = false;
      }
    },
    "orgForm.postData.businessType"() {
      this.details.businessType = this.details.businessType + 1;
      if (this.$route.path.includes("edit") && this.details.businessType > 1)
        this.navigationBlocker.val = false;
      else if (this.$route.path.includes("add")) {
        this.navigationBlocker.val = false;
      }
    },
    "orgForm.postData.businessName"() {
      this.details.businessName = this.details.businessName + 1;
      if (this.$route.path.includes("edit") && this.details.businessName > 1)
        this.navigationBlocker.val = false;
      else if (this.$route.path.includes("add")) {
        this.navigationBlocker.val = false;
      }
    },
    "orgForm.postData.contactName"() {
      this.details.contactName = this.details.contactName + 1;
      if (this.$route.path.includes("edit") && this.details.contactName > 1)
        this.navigationBlocker.val = false;
      else if (this.$route.path.includes("add")) {
        this.navigationBlocker.val = false;
      }
    },
    "orgForm.postData.contactEmail"() {
      this.details.contactEmail = this.details.contactEmail + 1;
      if (this.$route.path.includes("edit") && this.details.contactEmail > 1)
        this.navigationBlocker.val = false;
      else if (this.$route.path.includes("add")) {
        this.navigationBlocker.val = false;
      }
    },
    "orgForm.postData.contactPhone"() {
      this.details.contactPhone = this.details.contactPhone + 1;
      if (this.$route.path.includes("edit") && this.details.contactPhone > 1)
        this.navigationBlocker.val = false;
      else if (this.$route.path.includes("add")) {
        this.navigationBlocker.val = false;
      }
    },
  },
  computed: {
    ...mapState({
      businessType: (state) => state.master.businessType,
    }),
  },
  methods: {
    handleAddClick() {
      this.navigationBlocker.val = false;
      if (this.orgForm.postData.clientID) {
        this.DeviceLocation = {
          postData: new ClientLocationRequest(),
        };
        this.manageClient.showManageClientLocation = true;
        this.getDeviceList();
      } else {
        let information = new Information();
        information.title = "Info";
        information.description = "Create Client before adding Location";
        information.confirmButtonClass = "md-button md-info";
        this.showInformation(information);
      }
    },
  },
};
</script>
