<template>
  <ValidationListener
    @getIsValid="(isValid) => (DeviceLocation.isDataValid = isValid)"
  >
    <div>
      <loading :active="app.showLoader.value" :can-cancel="true"></loading>
      <div class="modal-header">{{ locationCardHeader }}</div>
      <div class="modal-content scroll">
        <div class="form-vertical m-0">
          <div class="form-layout-horizondal">
            <label class="w-25">Location Name *</label>
            <ValidationHandler
              :name="DeviceLocation.postData.location.name"
              rules="required|alpha_dash"
              v-slot="{ error }"
            >
              <TextInput
                type="text"
                label="Location Name "
                placeholder="Location Name "
                :required="true"
                v-model="DeviceLocation.postData.location.name"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div class="form-layout-horizondal">
            <label class="w-25">Region Name</label>
            <ValidationHandler
              :name="DeviceLocation.postData.location.regionID"
            >
              <select-input
                name="Region Name"
                displayName="Region Name"
                placeHolder="Region Name"
                :options="
                  regionList.map((x) => ({
                    value: x.id,
                    text: x.name,
                  }))
                "
                v-model="DeviceLocation.postData.location.regionID"
              />
            </ValidationHandler>
          </div>
          <div class="form-layout-horizondal" @click="show()">
            <label class="w-25">Device *</label>
            <span v-if="isEdit">
              <text-input
                v-model="DeviceLocation.postData.DeviceLocation.device.name"
                :readonly="true"
              />
            </span>
            <ValidationHandler
              :name="DeviceLocation.postData.DeviceLocation.deviceId"
              rules="required"
              v-slot="{ error }"
              v-else
            >
              <select-input
                name="Device"
                displayName="Device"
                placeHolder="Device "
                :required="true"
                :options="
                  deviceList.map((x) => ({
                    value: x.id,
                    text: x.name,
                  }))
                "
                v-model="DeviceLocation.postData.DeviceLocation.deviceId"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div v-show="deviceShow" class="form-layout-horizondal">
            <label class="w-25">Device Status</label>
            <ValidationHandler
              :name="DeviceLocation.postData.DeviceLocation.deviceStatus"
            >
              <select-input
                name="Device Status"
                displayName="Device Status"
                placeHolder="Device Status"
                :options="
                  deviceStatus.map((x) => ({
                    value: x.lookupId,
                    text: x.displayValue,
                  }))
                "
                v-model="DeviceLocation.postData.DeviceLocation.deviceStatus"
              />
            </ValidationHandler>
          </div>
          <div class="d-flex">
            <div v-show="deviceShow" class="form-layout-horizondal">
              <label class="w-25">Start Date</label>
              <text-input
                type="date"
                label="Start Date"
                :required="true"
                :readonly="isEdit"
                v-model="DeviceLocation.postData.DeviceLocation.activationDate"
              />
            </div>
            <div v-show="deviceShow" class="form-layout-horizondal">
              <label class="w-25">End Date</label>
              <text-input
                type="date"
                label="End Date"
                :required="true"
                v-model="DeviceLocation.postData.DeviceLocation.lastActiveDate"
              />
            </div>
          </div>
          <div>
            <div v-if="this.error == true">
              <h6 style="color: red; padding-left: 70px">
                ** End date cannot be less than start date **
              </h6>
              <p></p>
            </div>
          </div>
          <!-- class="form-layout-horizondal" -->
          <div class="form-layout-horizondal">
            <label class="w-25">Address*</label>
            <ValidationHandler
              :name="DeviceLocation.postData.location.address.address1"
              rules="required"
              v-slot="{ error }"
            >
              <TextInput
                type="text"
                label="Address "
                placeholder="Address "
                :required="true"
                v-model="DeviceLocation.postData.location.address.address1"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div class="d-flex">
            <div class="form-layout-horizondal">
              <!-- <div class="form-layout-horizondal"> -->
              <label class="w-25">City*</label>
              <ValidationHandler
                :name="DeviceLocation.postData.location.address.city"
                rules="required|alpha_spaces"
                class="w-25"
                v-slot="{ error }"
              >
                <TextInput
                  type="text"
                  label="City "
                  placeholder="City "
                  :required="true"
                  v-model="DeviceLocation.postData.location.address.city"
                  :error="error"
                />
              </ValidationHandler>
              <label class="w-auto">State*</label>
              <ValidationHandler
                :name="DeviceLocation.postData.location.address.state"
                rules="required"
                class="w-25"
                v-slot="{ error }"
              >
                <div>
                  <select-input
                    name="state"
                    displayName="State "
                    placeHolder="state "
                    :options="
                      stateList.map((x) => ({
                        value: x.id,
                        text: x.displayValue,
                      }))
                    "
                    v-model="DeviceLocation.postData.location.address.state"
                    :required="true"
                    :error="error"
                  />
                </div>
              </ValidationHandler>
            </div>
          </div>
          <div class="d-flex">
            <div class="form-layout-horizondal">
              <!-- <div class="form-layout-horizondal"> -->
              <label class="w-25">Country *</label>
              <span class="w-25">
                <ValidationHandler
                  :name="DeviceLocation.postData.location.address.country"
                >
                  <select-input
                    name="country"
                    displayName="Country *"
                    placeHolder="Country *"
                    :required="true"
                    :options="
                      countryCodeList.map((x) => ({
                        value: x.id,
                        text: x.displayValue,
                      }))
                    "
                    v-model="DeviceLocation.postData.location.address.country"
                  />
                </ValidationHandler>
              </span>
              <label class="w-10">Zip</label>
              <span class="w-25">
                <ValidationHandler
                  :name="DeviceLocation.postData.location.address.zipCode"
                  rules="required|min:5|max:16"
                  v-slot="{ error }"
                >
                  <TextInput
                    type="text"
                    label="Zip Code *"
                    placeholder="Zip Code *"
                    :required="true"
                    v-model="DeviceLocation.postData.location.address.zipCode"
                    :error="error"
                    v-mask="'#####-#####-####'"
                  />
                </ValidationHandler>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <md-button class="btn-outline" @click="handleCancelClick"
          >Cancel
        </md-button>
        <div>
          <div v-if="this.error == true"></div>
          <div v-else>
            <md-button class="md-primary" @click="handleLocationSave"
              >Submit
            </md-button>
          </div>
        </div>
      </div>
    </div>
  </ValidationListener>
</template>
<script>
import TextInput from "../../components/controls/TextInput.vue";
import SelectInput from "../../components/controls/SelectInput.vue";
import { mapState } from "vuex";
export default {
  inject: [
    "DeviceLocation",
    "handleLocationSave",
    "manageClient",
    "handleUpdateDeviceLocation",
    "handleCancelClick",
    "app",
  ],
  components: {
    TextInput,
    SelectInput,
  },
  computed: {
    ...mapState({
      countryCodeList: (state) => state.master.countryCodeList,
      stateList: (state) => state.master.stateList,
      deviceStatus: (state) => state.master.deviceStatus,
      regionList: (state) => state.master.regionList,
      deviceList: (state) => state.Client.deviceList,
      datewatch() {
        var lastactivedate = new Date(
          this.DeviceLocation.postData.DeviceLocation.lastActiveDate
        );
        var activedate = new Date(
          this.DeviceLocation.postData.DeviceLocation.activationDate
        );
        if (lastactivedate < activedate) return (this.error = true);
        else return (this.error = false);
      },
    }),
  },
  data() {
    return {
      deviceShow: false,
      locationCardHeader: "",
      error: false,
      isEdit: false,
    };
  },
  watch: {
    datewatch() {},
  },
  created() {
    if (this.DeviceLocation.postData.DeviceLocation.id) {
      this.locationCardHeader = "Edit Location";
      this.deviceShow = true;
      this.isEdit = true;
    } else {
      this.locationCardHeader = "Add Location";
    }
  },
  methods: {
    show() {
      this.deviceShow = true;
    },
  },
};
</script>
